import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ResetPwdComponent } from '../reset-pwd/reset-pwd.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {
  @Output() onSignIn = new EventEmitter<{ email: string, password: string }>();
  @Input() isLoading = false;
  signInForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])
    ),
    password: new UntypedFormControl(
      '',
      Validators.compose([Validators.minLength(6), Validators.required])
    ),
  });
  constructor(
    public router: Router,
    private _graphql: GraphqlService,
    private _sharedService: SharedService,
    private _translateService: TranslateService,
    public dialog: MatDialog,
  ) { }

  signInWithEmail() {
    this.onSignIn.emit(
      {
        email: this.signInForm.value.email,
        password: this.signInForm.value.password
      }
      );
  }

  onResetPwd() {
    this.dialog.open(ResetPwdComponent).afterClosed().pipe(take(1))
      .subscribe(data => {
        if (data)
          if (data.success)
            this._sharedService.makeToast(this._translateService.instant("emailSent"), this._translateService.instant("emailSentMessage"), 'success');
          else
            this._sharedService.makeToast(this._translateService.instant("error"), this._translateService.instant(data.message), 'danger');
      });
  }
}
