<ion-content>
  <app-header title="umanweb"></app-header>
  <ion-row>
    <ion-col style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 80vh;
      ">
      <img [src]="assetsPath + '/images/logo-big.jpeg'" style="width: 240px; height: 240px" />
      <p style="
    font-weight: bold; 
    text-align: center; 
    font-size: 16px;
    margin-top: 16px; 
    margin-bottom: 16px;
    ">{{ "umanwebAccess" | translate | capitalizeFirstLetter }}</p>
      <app-sign-in *ngIf="!isSignup" [isLoading]="isSigninLoading" (onSignIn)="signIn($event)"></app-sign-in>
      <app-create-user *ngIf="isSignup" [isLoading]="isCreateUserLoading" (onCreateUser)="createUser($event)"></app-create-user>

  <ion-row style="margin-top: 2%; justify-content: space-between;">
    <ion-button mode="ios" fill="clear" size="small" (click)="onResetPwd()"
      ><p>{{"pwdForgotten" | translate | capitalizeFirstLetter}}</p></ion-button
    >
    <ion-button mode="ios" fill="clear" size="small" (click)="isSignup = !isSignup"
      ><p>{{(isSignup? "login" : "createAccount") | translate | capitalizeFirstLetter}}</p></ion-button
    >
  </ion-row>
    </ion-col>
  </ion-row>
</ion-content>