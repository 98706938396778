<div style="padding: 24px;">
  <h1 mat-dialog-title>{{"resetPwd" | translate | capitalizeFirstLetter}}</h1>
  <ion-row>
    <h3>
      {{"resetPwd" | translate | capitalizeFirstLetter}}
    </h3>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <form style="text-align: center" [formGroup]="form">
        <!-- Email field -->
        <mat-form-field appearance="outline" color="accent">
          <mat-label>{{"email" | translate | capitalizeFirstLetter}}</mat-label>
          <input id="email" matInput [formControlName]="'email'" />
        </mat-form-field>
        <br />
        <button mat-flat-button color="accent" [disabled]="!form.valid || isLoading" (click)="resetPwd()"
          style="border-radius: 0px; text-align: -webkit-center">
          {{"send" | translate | capitalizeFirstLetter}}
        </button>
        <button mat-flat-button color="primary" mat-dialog-close
          style="border-radius: 0px; text-align: -webkit-center; margin-left: 5%">
          {{"cancel" | translate | capitalizeFirstLetter}}
        </button>
      </form>
      <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;" *ngIf="isLoading"
        [diameter]="24" [mode]="'indeterminate'" color="accent">
      </mat-progress-spinner>
    </ion-col>
  </ion-row>
</div>