import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';
import { finalize, take } from 'rxjs/operators';
import { GraphqlService } from '../core/graphql/graphql.service';
import { authLogin } from '../core/auth/auth.actions';
import { setUser } from '../core/user/user.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '../core/auth/auth.models';
import { ErrorDialogComponent } from '../shared/components/error-dialog/error-dialog.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  isSmallScreen: boolean;
  sub: Subscription;
  assetsPath = environment.assetspath;
  isSignup = false;
  isCreateUserLoading=false;
  isSigninLoading=false;
  isDialogOpen: boolean = false;
  msg = '';
  constructor(
    public router: Router,
    private _sharedService: SharedService,
    private _translateService: TranslateService,
    private _store: Store<AuthState>,
    private _graphql: GraphqlService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit() {
  
    this.sub = this.sharedService.isSmallScreen.subscribe((isSmallScreen) => {
      this.isSmallScreen = isSmallScreen;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onResetPwd() {
    this.dialog.open(ResetPwdComponent).afterClosed().pipe(take(1))
      .subscribe(data => {
        if (data)
          if (data.success)
            this._sharedService.makeToast(this._translateService.instant("emailSent"), this._translateService.instant("emailSentMessage"), 'success');
          else
            this._sharedService.makeToast(this._translateService.instant("error"), this._translateService.instant(data.message), 'danger');
      });
  }

  createUser(user:any){
    this.isCreateUserLoading = true;
    this._graphql.createUser(
      user.firstname,
      user.lastname,
      user.email,
      user.etab,
      user.street,
      user.number,
      user.zip,
      user.city,
      user.password,
      true
    )
      .pipe(
        take(1),
        finalize(() => this.isCreateUserLoading = false)
      )
      .subscribe(
        () => this.signIn({email: user.email, password: user.password}),
        (error) => alert(error),
      )
  }

  openDialog(title: string, msg: string) {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: {
          title,
          msg,
        },
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
        this.isDialogOpen = false;
      });
    }
  }

  signIn(credentials){
    this.isSigninLoading = true;
    this._graphql.login(
      credentials.email,
      credentials.password
    )
      .subscribe(({ data }: any) => {
        this.isSigninLoading = false;
        this._store.dispatch(authLogin());
        this._store.dispatch(setUser({ user: { ...data.tokenAuth.user } }));
      },
        (error) => {
          this.isSigninLoading = false;
          const title = this._translateService.instant("authFailed");
          this.openDialog(title, error);
          this.cdr.detectChanges();
        }
      )
  }
}
